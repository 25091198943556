/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { Container, Layout } from '../components';
import * as api from '../utils/api';
import { navigate } from 'gatsby';
import { Form } from 'formik';
import Button from '../components/Button';
import FormField from '../components/FormField';
import * as queryString from 'query-string';
import PropTypes from 'prop-types';
import { showNotification, SEVERITY, hideAllNotifications } from '../state/notifications';
import * as analytics from '../utils/analytics';

const resetPasswordSchema = translate =>
  yup.object().shape({
    password: yup
      .string()
      .min(6, translate('register.message.tooShort'))
      .required(translate('register.message.requiredField')),
    passwordAgain: yup
      .string()
      .required(translate('register.message.requiredField'))
      .oneOf([yup.ref('password')], translate('register.message.passwordMismatch')),
  });

const ResetPasswordForm = ({ translate, handleSubmit, isSubmitting, setFieldValue }) => {
  return (
    <Form sx={{ mb: 4 }}>
      <FormField label={translate('register.form.password')} type="password" name="password" />
      <FormField label={translate('register.form.passwordAgain')} type="password" name="passwordAgain" />
      <Button style={{ alignSelf: 'flex-end' }} type="submit" onClick={handleSubmit} disabled={isSubmitting}>
        {translate('resetPassword.resetButton')}
      </Button>
    </Form>
  );
};

const ResetPasswordFormik = withFormik({
  mapPropsToValues: ({ vo }) =>
    Object.assign({
      password: vo.password || '',
      passwordAgain: vo.passwordAgain || '',
    }),
  validationSchema: ({ translate }) => {
    return resetPasswordSchema(translate);
  },
  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    onSubmit(values, actions);
  },
  displayName: 'ResetPasswordInputForm',
})(ResetPasswordForm);

const PasswordResetPage = props => {
  analytics.usePageCategory('omat_sivut');
  const localize = useSelector(state => state.localize);
  const translate = getTranslate(localize);
  const [vo, setVO] = useState({});
  const queryParams = queryString.parse(props.location.search);

  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (vo, { setSubmitting }) => {
      dispatch(hideAllNotifications());
      if (!queryParams.token) {
        dispatch(showNotification('resetPassword.message.resetPasswordFailed'));
        setSubmitting(false);
        return;
      }
      try {
        const trimmedPassword = vo.password.trim();
        const response = await api.resetPassword(trimmedPassword, queryParams.token);
        console.log(response);
        dispatch(showNotification('resetPassword.message.success', SEVERITY.INFO));
        setVO({});
        setTimeout(() => {
          navigate('/');
        }, 3000);
      } catch (error) {
        console.error('Reset Password Failed:', error);
        dispatch(showNotification('resetPassword.message.resetPasswordFailed'));
      }
      setSubmitting(false);
    },
    [dispatch, queryParams]
  );
  const formProps = { vo, onSubmit, translate };

  return (
    <Layout
      title={translate('page.changePassword')}
      paths={props.pageContext.paths}
      locale={props.pageContext.locale || 'en'}
    >
      <Container sx={{ maxWidth: 640 }}>
        <Styled.h1>{translate('page.changePassword')}</Styled.h1>
        <ResetPasswordFormik {...formProps} />
      </Container>
    </Layout>
  );
};

PasswordResetPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default PasswordResetPage;
